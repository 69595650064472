.headerContainer {
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  width: 100%;
}
.webLogoStyle {
  width: 35%;
}

.listContainer {
  padding: 10px 20% 10px 20%;
}

@media only screen and (min-width: 200px) and (max-width: 640px) {
  .listContainer {
    padding: 10px 10% 10px 10%;
  }
}
